import React, { useState } from 'react';
import { Card, Row, Col, List, Tag, Space, Typography, Popconfirm, Button, Avatar, Tooltip, Statistic, message, Select } from 'antd'
import axios from 'axios';
import { DeleteOutlined, QuestionCircleOutlined, CopyOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons"
import { AiOutlineEdit, AiOutlineFilePdf, } from 'react-icons/ai';
import moment from "moment";
//componentes
import CustomAvatar from '../Avatar';
import User from '../../../Hooks/Logged';
import { CodificarCuenta } from '../../Utils';
import usePermissions from '../../../Hooks/usePermissions';

import Logged from '../../../Hooks/Logged';
//css
import '../../../Styles/Global/cards.scss'
import './cards.css'

const { Text, Title } = Typography;
const { Option } = Select;

/**
 * @const CardResumenFinanciero
 * @description Card que muestra los montos de ingresos o egresos, para el dashboard
 */
const CardResumenFinanciero = ({
    title = "",
    monto = 0,
    porcentaje = 0,
    meta = 0,
    tipo = 1,
    avance = 0,
    validacion = true,
    select = false,
    onSelect = () => {},
}) => {

    let options = []

    const fecha_inicial = moment('2023-07-01');
    let fecha_actual = moment();

    let fechaActualTemp = fecha_inicial.clone();
    while (fechaActualTemp.isSameOrBefore(fecha_actual, 'month')) {
        options.unshift(<Option value={fechaActualTemp.format("MM-YYYY")}> {fechaActualTemp.format("MMMM YYYY").toUpperCase()} </Option>);
        fechaActualTemp.add(1, 'month');
    }

    return <Card
        className="card-resumen"
        size="small"
    >
        <Row>
            <Col span={24} className="flex-left-column">
                <Text className="title">{title}</Text>
                <Text className="monto">$ {validacion ? monto?.toMoney(true) : 'N/A'}  <small>MXN</small></Text>
                { select ? <Select
                    style={{minWidth: "200px"}}
                    placeholder={"Mes"}
                    size="small"
                    defaultValue={moment().format("MM-YYYY")}
                    onSelect={onSelect}
                >
                    {options}
                </Select> : null }
            </Col>
        </Row>
    </Card>
}

/**
 * @const CardTransaccionReciente
 * @description Card que muestra la transaccion en un card pequeño, para el dashboard
 */
const CardTransaccionReciente = ({
    concepto = "SPEI Mario Vargas",
    monto = 0,
    referencia_numerica = "",
    monto_total = null,
    tipo = null,
    ordenante = {},
    estado,
    customer = false
}) => {

    return <Card
        className="card-transaccion-reciente"
        size="small"
    >
        <Row>
            <Col xs={4} xl={3} xxl={2} className="center">
                <CustomAvatar
                    color={tipo === 1 ? "#4caa21" : "#bb2a2a"}
                    name={ordenante?.nombre}
                />
            </Col>
            <Col xs={12} xl={12} xxl={14} className="flex-left-column">
                <Text className="text-concepto">{concepto}</Text>
                <Text className="text-info">{CodificarCuenta(ordenante.cuenta)} referecnia: {referencia_numerica}</Text>
            </Col>
            <Col xs={8} xl={8} xxl={8} className="flex-right-column">
                <Statistic
                    value={customer ? monto.toMoney(true) : monto_total?.toMoney(true)}
                    precision={2}
                    prefix={tipo === 2 ? "-$" : "$"}
                    valueStyle={{
                        fontWeight: 'bold',
                        fontSize: 16,
                        color: tipo === 1 ? "#4caa21" : "#bb2a2a",
                    }}
                />
                <small>{estado}</small>
            </Col>
        </Row>
    </Card>
}

/**
 * @const CardCuenta
 * @description Card que muestra la transaccion en un card pequeño, para el dashboard
 */
const CardCuenta = ({
    nombre = "Cuenta STP",
    balance = 0,
    concepto = "SPEI Mario Vargas",
    referencia = "1345678F",
    cuenta = "0000",
    saldo_bloqueado = 0,
    cuenta_bloqueada = false,
    concentradora = false,
    spei_balance = 0,
    uso_balance = 0,
    validacion = true,
    total_cuentas = 0
}) => {

    const user = React.useContext(Logged)
    const [clabeShow, setClabeShow] = useState(false);

    let permissions = usePermissions(user?.rol_id?.permisos, {
        estadoCuenta: ["transacciones", "read"],
        puedeVerSaldo: ["cuentas", "ver_saldo"]
        
    })
    const copyCuenta = () => {
        navigator.clipboard.writeText(cuenta);
        message.success("¡CLABE copiada!")
    }

    const show = () => {
        setClabeShow(!clabeShow)
    }

    return <Card
        className="card-cuenta"
        size="small"
    >
        <Row>
            <Col span={24}>
                <Text className="text-name">{nombre}</Text> {cuenta_bloqueada ? <Tooltip
                    placement="topLeft"
                    title={"Cuenta temporalmente bloqueada por exceder límites; egresos bloqueados."}
                > <Tag color={"red"}>Cuenta Bloqueda <QuestionCircleOutlined /> </Tag> </Tooltip> : null}
            </Col>
            <Col span={24} className="flex-left-column">
                <Text className="balance">
                    {concentradora ? "Saldo Global" : "Balance Total"}&nbsp;
                    <Tooltip
                        placement="topLeft"
                        title={`${concentradora ? "Saldo Global" : "Balance Total"}: representa la cantidad total de fondos en la cuenta`}
                    >
                        <QuestionCircleOutlined />
                    </Tooltip>
                </Text>
                {/* user?.rol_id?.tipo == 4 || permissions.puedeVerSaldo */}
                {/* {console.log('X',user?.rol_id?.tipo)}
                {console.log('Y',permissions.puedeVerSaldo)} */}
                <Text className="saldo">$ {(user?.rol_id?.tipo == 4 && permissions.puedeVerSaldo) ? total_cuentas.toMoney(true) : ((validacion && permissions.puedeVerSaldo)?   (balance + saldo_bloqueado).toMoney(true) : "N/A")}</Text>
                {   
                    concentradora ? <>
                        <Text>Saldo SPEI: $ {spei_balance?.toMoney(true)} mxn</Text>
                        <Text>Saldo Uso de cuenta: $ {uso_balance?.toMoney(true)} mxn</Text>
                    </> : null
                }

            </Col>
            <Col span={24} className="flex-left-column">
                <Text className="cuenta">{cuenta.hideCard(clabeShow)} <CopyOutlined onClick={copyCuenta} /> {clabeShow ? <EyeInvisibleOutlined onClick={show} /> : <EyeOutlined onClick={show} />} </Text>
            </Col>
        </Row>
    </Card>
}

const getPDF = (cuenta) => {
    if (cuenta.cuenta) {
        let urlPDF = new URL(axios.defaults.baseURL + '/cuenta/estado');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('cuenta', cuenta.cuenta)
        return urlPDF.href
    }
}

/**
 * @const CardCuentaInfo
 * @description Card que muestra la transaccion en un card pequeño, para el dashboard
 */
const CardCuentaInfo = ({
    title = "Cuenta Concentradora",
    subtitle = "Cuentas Cliente",
    data = [],
    loading = false,
    cuenta = {},
    customer = false,
    concentradora = false,
    spei_balance = 0,
    uso_balance = 0,
    validacion = true,
    openModal,
}) => {

    let user = React.useContext(User)
    let permissions = usePermissions(user?.rol_id?.permisos, {
        estadoCuenta: ["transacciones", "read"],
        puedeVerSaldo: ["cuentas", "ver_saldo"]
        
    })

    let [pdfLoading, setPdfLoading] = useState(false)

    return <Card
        className="card-cuenta-big"
        loading={loading}
    >
        <Row>
            <Col span={24}>
                <Row justify={"space-between"}>
                    <Text className="title">{title}</Text>
                    {
                        cuenta?.cuenta ? (
                            permissions.estadoCuenta ? (
                                <Button
                                    title="Ver estado de cuenta"
                                    type='primary'
                                    className='btn-primary center'
                                    icon={<AiOutlineFilePdf />}
                                    disabled={false}
                                    loading={pdfLoading}
                                    onClick={() => {
                                        if(openModal && cuenta.tipo === 40){
                                            openModal()
                                        }else{ 
                                            
                                            setPdfLoading(true)
                                            axios.get(getPDF(cuenta), { responseType: 'blob', })
                                                .then(response => window.open(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))))
                                                .catch(error => {
                                                    message.error("Error al obtener el Archivo")
                                                })
                                                .finally(e => setPdfLoading(false))
                                        }
                                    }}
                                />
                            ) : (
                                <Tooltip title="No estas autorizado" placement='topLeft'>
                                    <Button
                                        title="Ver estado de cuenta"
                                        type='primary'
                                        className='btn-primary center'
                                        icon={<AiOutlineFilePdf />}
                                        disabled={true}
                                    />
                                </Tooltip>
                            )
                        ) : (
                            <Tooltip title="Selecciona una cuenta" placement='topLeft'>
                                <Button
                                    title="Ver estado de cuenta"
                                    type='primary'
                                    className='btn-primary center'
                                    icon={<AiOutlineFilePdf />}
                                    disabled={true}
                                />
                            </Tooltip>
                        )
                    }
                </Row>

            </Col>
            <Col span={24} className="center">
                <CardCuenta validacion={validacion} {...cuenta} concentradora={concentradora} spei_balance={spei_balance} uso_balance={uso_balance} />
            </Col>
            <Col span={24} className="pt-1 pb-1">
                <Text className="title">{subtitle}</Text>
            </Col>
            <Col span={24} className="cuentas-beneficiarios-culumn">
                {data?.map((e, index) => {
                    return <CuentaBeneficiario {...e} customer={customer} key={index} />
                })}
            </Col>
        </Row>
    </Card>
}

const CuentaBeneficiario = (e) => {

    const [clabeShow, setClabeShow] = useState(false);

    const show = () => {
        setClabeShow(!clabeShow)
    }

    const copyCuenta = (clabe) => {
        navigator.clipboard.writeText(clabe);
        message.success("¡CLABE copiada!")
    }


    return <Row className="row-cuenta" >
        <Col xs={4} xl={4} className="center">
            <CustomAvatar name={e.nombre ?? '' + e?.apellido_materno ?? '' + e?.apellido_paterno ?? ''} />
        </Col>
        <Col xs={20} xl={20} className="flex-left-column">
            <Text className="text-concepto">{e.nombre ?? '' + e?.apellido_materno ?? '' + e?.apellido_paterno ?? ''} </Text>

            <Text className="text-info">{e?.cuenta.hideCard(clabeShow)} <CopyOutlined onClick={() => copyCuenta(e?.cuenta)} /> {clabeShow ? <EyeInvisibleOutlined onClick={show} /> : <EyeOutlined onClick={show} />}</Text>
            {e.customer ? null : <small> $ {e.balance?.toMoney(true)} mxn</small>}

        </Col>
    </Row>
}

const CardTemplateRegla = ({
    onClick = () => { },
    reglas = [],
    tipo = 0,
    activo = false,
    onDelete = () => { } }) => {
    return <Card
        className='card-template'
        hoverable={false}
        cover={<span alt="cover" className='card-bg-cover' >
            <Space direction='horizontal' align="baseline" className='float-right'>
                <Button type='primary' icon={<AiOutlineEdit />} className="align-end" onClick={onClick}></Button>
                <Popconfirm
                    placement="topRight"
                    title="¿Deseas eliminar este template?"
                    onConfirm={onDelete}
                    okText="Sí"
                    cancelText="No"
                >
                    <Button type='primary' icon={<DeleteOutlined />} className="align-end"></Button>
                </Popconfirm>
            </Space>

        </span>}
    >
        <Row>
            <Col span={24} style={{ height: 40 }}>
                <Avatar size={100} className='card-avatar' src="/img/background/bg-landing.png" />
            </Col>
            <Col span={24}>
                <Title level={4} className='text-color card-title-separated' >Template {tipo == 0 ? "SPEI" : "Uso de Cuenta"} </Title>
                {activo == true ? <Tag color="#3EA741" className='center'>Activo</Tag> : <Tag color="#FF0000" className='center'>Inactivo</Tag>}
            </Col>
        </Row>


        <List
            size="small"
            dataSource={reglas}
            renderItem={regla => <List.Item className='grid-noborder pl-0 pr-0'>
                <Card.Grid className='card-grid-80-20 border'>
                    Desde {regla.limite_inferior?.toMoney(true)} Hasta {regla.limite_superior?.toMoney(true)}
                </Card.Grid>
                {(regla.monto_absoluto && regla.monto_absoluto !== 0) ? <Card.Grid className='card-grid-20-80 border'>$ {regla.monto_absoluto.toMoney(true)}</Card.Grid> : null}
                {(regla.porcentaje && regla.porcentaje !== 0) ? <Card.Grid className='card-grid-20-80'> {regla.porcentaje} %</Card.Grid> : null}

            </List.Item>}
        />
    </Card>
}

/**
 * 
 * @param {Array} reglas 
 * @param {String} title 
 * @returns 
 */
const CardListReglasTemplate = ({
    reglas = [],
    title = "Transacciones al mes"
}) => {
    return <List
        size="small"
        className=' list-rules'
        dataSource={reglas}
        header={<>
            <Row className='width-100'>
                <Col span={16}>{title}</Col>
                <Col span={4}>Monto</Col>
                <Col span={4}> Porcentaje</Col>
            </Row>
        </>}
        renderItem={regla => <List.Item className='borderless'>
            <Row className='width-100'>
                <Col span={16}>Desde {regla.limite_inferior} Hasta {regla.limite_superior} </Col>
                <Col span={4}>$ {(regla.monto_absoluto).toMoney(true)}</Col>
                <Col span={4}> {regla.porcentaje} %</Col>
            </Row>
        </List.Item>}
    />

}


export {
    CardResumenFinanciero,
    CardTransaccionReciente,
    CardCuentaInfo,
    CardCuenta,
    CardTemplateRegla,
    CardListReglasTemplate
}