import React, { Component, useContext, useState } from "react";
import { Row, Col, Modal, Typography, Form, Input, message, Tabs, Spin, InputNumber, Button, Select, Space, TimePicker, DatePicker, Divider } from 'antd';
import { ArrowLeftOutlined } from "@ant-design/icons";
import { IconMoneda } from '../../Widgets/Icons';
import { SelectCuenta, SelectBeneficiario, SelectTipoPago } from "../../Widgets/Inputs/Selects";
import { FormBeneficiario } from "../Beneficiarios/ModalBeneficiario";
import axios from "axios"
import { getResponseError } from "../../Utils";
import usePermissions from "../../../Hooks/usePermissions";
import Logged from "../../../Hooks/Logged";
import Modal2FA from "../../Auth/Modal2FA";

const moment = require('moment-timezone')
moment.tz.setDefault('America/Mexico_City');

const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;


class FormTransferencia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cliente_id: null,
            cond: [],
            balance: undefined,

            modalVisible2FA: false,
        }
    }
    modalRef = React.createRef();


    componentDidMount() {

        if (this.props.cuenta_id) {
            this.modalRef.current.setFieldsValue({
                cuenta_id: {
                    value: this.props.cuenta_id,
                }
            })
            this.onSelectCuenta(this.props.cuenta_id)
        }

        if (this.props.user_rol !== 1) {
            this.setState({
                cond: [{
                    require: true,
                    message: "Por favor, seleccione el beneficiario"
                }]
            })
        }

        if (this.props.user_rol === 1) {
            this.onSelectCuenta("concentradora")
        }


        if (this.props.transaccion_id != null)
            this.get()

    }



    componentDidUpdate(prevProps) {
        if (this.props?.beneficiario?._id && this.props?.beneficiario?._id?.toString() !== prevProps.beneficiario?._id?.toString()) {
            this.modalRef.current.setFieldsValue({
                beneficiario_id: {
                    value: this.props.beneficiario._id,
                    label: <><Text strong>{this.props.beneficiario.nombre ?? ""} </Text>
                        &nbsp;   <small>{this.props.beneficiario.cuenta ?? ""}</small></>
                }
            })
        }

    }

    /**
   * @memberOf FormTransferencia
   * @method get
   * @descripcion Obtiene la transferencia
   * */
    get = () => {
        this.setState({ loading: true })
        axios.get('/transacciones/' + this.props.transaccion_id, {
            params: {
                id: this.props.transaccion_id
            }
        }).then(({ data }) => {
            this.modalRef.current.setFieldsValue({
                cliente_id: {
                    value: data.ordenante._id,
                    label: data.ordenante.nombre
                },
                cuenta_id: data.ordenante?.cuenta,
                beneficiario_id: data.beneficiarios[0]?._id,
                ...data
            })
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener la información de la transacción"))
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
     * @memberof ModalTransferencia
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        this.setState({ loading: true })

        values.monto = values.monto.toFixed(2)


        console.log(this.props.usuario?.auth_2FA);
        console.log(this.props.usuario);
        if (this.props.usuario?.auth_2FA) 
            return this.setState({
                values,
                modalVisible2FA: true
            })

        if (this.props.transferir)
            this.add(values)

        if (this.props.capturar)
            this.capturar(values)
    }

    /**
     *
     *
     * @memberof FormTransferencia
     * @description Peticion para crear transacciones que se validen con STP
     */
    add = (values) => {

        this.setState({ modalVisible2FA: false })
        return axios.post('/transacciones', {
            cliente_id: this.props.cliente_id ? this.props.cliente_id : this.state.cliente_id,
            ...values,
        })
            .then(response => {
                message.success('Transacción creada.')
                this.props.onClose(response.data)

            })
            .catch(error => {
                if (typeof error.response?.data?.error_auth == "boolean") {
                    // message.error("El Token no es válido")
                    // this.setState({modalVisible2FA: true})
                    Modal.error({
                        title: "El TOKEN de Sesión no es válido"
                    })
                } else
                    Modal.info({
                        title: 'Error al crear transacción.',
                        width: 400,
                        content: getResponseError(error.response, 'Ocurrio un error al crear transacción.'),
                        onOk() { },
                        okText: "Cerrar"
                    })
            })
            .finally(() => this.setState({ loading: false }))

    }


    /**
     *
     *
     * @memberof FormTransferencia
     * @description Peticion para crear transacciones locales sin necesidad de enviarlas a STP porque se ejecutaran en otro momento
     */
    capturar = (values) => {

        values.hora_ejecucion = {
            hora: moment(values.hora_ejecucion).format('HH'),
            minutos: moment(values.hora_ejecucion).format('mm')
        }

        // console.log('values.fecha_ejecucion',  values.fecha_ejecucion.format("YYYY-MM-DD HH:MM"))
        values.fecha_ejecucion = moment(values.fecha_ejecucion).tz("America/Mexico_City").format("YYYY-MM-DD HH:MM")
        // console.log('values.fecha_ejecucion',  values.fecha_ejecucion.format("YYYY-MM-DD HH:MM"))

        axios.post('/transacciones/capturar', {
            cliente_id: this.props.cliente_id ? this.props.cliente_id : this.state.cliente_id,
            ...values,
        }).then(response => {
            message.success('Transacción programada creada.')
            this.props.onClose(response.data)

        }).catch(error => {
            message.error(getResponseError(error.response, 'Error al crear la transacción programada.'))
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     *
     *
     * @param {*} current
     * @returns
     * @memberof FormTransferencia
     * 
     * @description Deshabilita las horas en las que ya no aplica la transaccion segun stp
     */
    disabledTimes(current) {
        if (current) {
            const hour = current.hour();
            if (hour > 17) {
                return {
                    disabledHours: () => [18, 19, 20, 21, 22, 23],
                    disabledMinutes: () => [],
                    disabledSeconds: () => [],
                };
            }
        }
        return {};
    }


    /**
     *
     *
     * @memberof FormTransferencia
     * @descripcion Para el admin, puede escoger entre cuenta y beneficiario
     * Si el beneficiario se ingresa, la cuenta se quita
     */
    onSelectBeneficiario = () => {
        if (this.props.user_rol == 1) {
            this.modalRef.current.setFieldsValue({ cuenta_opcional: null })
        }
    }

    /**
     * @memberof FormTransferencia
     * @descripcion Cuando un cliente se haya seleccionado una cuenta, un beneficiario y un monto, se hace la peticion para caulcular la comision a pagar
     */
    handleBlur = () => {
        if (this.props.cliente) {
            let { beneficiario_id, cuenta_id, monto } = this.modalRef.current.getFieldsValue();
            if (beneficiario_id && cuenta_id && monto !== undefined) {
                axios.get('/cuenta/comision', {
                    params: {
                        beneficiario_id: beneficiario_id,
                        cuenta_id: cuenta_id,
                        monto: monto,
                    }
                }).then(({ data }) => {
                    console.clear()
                    console.log("data", data);
                    this.setState({
                        comision: data.comision,
                        monto_total: data.monto_total
                    })
                }).catch(error => {
                    console.log("error", error);

                })
            }
        }
    }

    /**
    * @memberof FormTransferencia
    * @descripcion Cuando se haya seleccionado la cuenta se monstrara el saldo diponible de esta
    */
    onSelectCuenta = (cuenta_id) => {
        axios.get('/cuenta', {
            params: {
                cuenta_id
            }
        })
            .then(response => {
                this.setState({ balance: response.data.balance - response.data.balance_congelado })

            }).catch(error => {
                console.log("error", error);
                message.error("Error al obtener el saldo")
            })
    }


    renderSaldoDisponible = () => {
        if ((this.props.usuario?.rol_id?.tipo != 3) || (this.props.puedeVerSaldo && this.props.usuario?.rol_id?.tipo == 3))
            return <Row>
                <Col span={24}>
                    {this.state.balance !== undefined ? <Text>Saldo Disponible: $ {this.state.balance?.toMoney(true)} mxn</Text> : 'No hay balance disponible'}
                </Col>
            </Row>
    }


    render() {
        return (

            <Form
                layout="vertical"
                ref={this.modalRef}
                onFinish={this.onFinish}
                className="pl-1 pr-1"
                initialValues={{
                    fecha_ejecucion: moment().hour(0).minute(0),
                    tipo_pago: 1,
                    tipo: this.props.tipo ?? 2
                }}
                validateTrigger={"onBlur"}
                onValuesChange={this.onValuesChange}
            >
                <Spin spinning={this.state.loading}>
                    {/**si no soy admin */}
                    {this.props.user_rol != 1 ?
                        <Form.Item
                            label={this.props.transaccion_id ? "Cuenta" : <>Obligatorio</>}
                            name="cuenta_id"
                            rules={[{
                                required: true,
                                message: "Por favor, seleccione la cuenta"
                            }]}
                        >
                            <SelectCuenta
                                onBlur={this.handleBlur}
                                defaultActiveFirstOption={true}
                                size="large"
                                params={{ cliente_id: this.props.cliente_id ?? this.state.cliente_id }}
                                suffixIcon={true}
                                placeholder="Cuenta Emisora"
                                onSelect={this.onSelectCuenta}
                            />
                        </Form.Item> :
                        <Form.Item
                            label={this.props.transaccion_id ? "Cuenta" : <></>}
                            name="cuenta_opcional"
                        >
                            <SelectCuenta
                                defaultActiveFirstOption={true}
                                size="large"
                                params={{ cliente_id: this.props.cliente_id ?? this.state.cliente_id }}
                                suffixIcon={true}
                                placeholder="Cuenta Destino"
                                allowClear={true}
                                onSelect={() => this.modalRef.current.setFieldsValue({ beneficiario_id: null })}
                            />
                        </Form.Item>
                    }
                    <Form.Item
                        label={this.props.transaccion_id ? "Beneficiario" : <></>}
                        name="beneficiario_id"
                        extra={<a onClick={() => this.props.setKey("2")}> + Nuevo Beneficiario</a>}
                        rules={this.state.cond}
                        shouldUpdate={true}
                    >
                        <SelectBeneficiario
                            onBlur={this.handleBlur}
                            size="large"
                            placeholder="Beneficiario"
                            suffixIcon={true}
                            showSearch={true}
                            params={{ cliente_id: this.props.cliente_id ?? this.state.cliente_id, admin: (this.props.user_rol == 1) }}
                            onSelect={this.onSelectBeneficiario}

                        />
                    </Form.Item>


                    <Form.Item
                        label={this.props.transaccion_id ? "Concepto" : <>Obligatorio</>}
                        name="concepto"
                        rules={[
                            {
                                required: true,
                                message: "Por favor, ingrese el concepto"
                            },
                            {
                                max: 30,
                                message: 'El concepto debe tener un máximo de 30 caracteres.'
                            },
                            {
                                pattern: /^[a-zA-Z0-9 ]*$/,
                                message: 'El concepto debe ser alfanumérico.'
                            }

                        ]}
                    >
                        <Input
                            placeholder="concepto"
                            size="large"
                        />
                    </Form.Item>
                    {this.renderSaldoDisponible()}

                    <Form.Item
                        label={this.props.transaccion_id ? "Monto" : <>Obligatorio</>}
                        name="monto"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el monto"
                        }]}
                        extra={
                            this.state.comision > 0 ? <Row>
                                <Col span={12}>
                                    <Text>Comisión: $ {this.state.comision?.toMoney(true)} mxn</Text>
                                </Col>
                                <Col span={12}>
                                    <Text>Total: $ {this.state.monto_total?.toMoney(true)} mxn</Text>
                                </Col>
                            </Row> : null
                        }
                    >
                        <InputNumber
                            controls={false}
                            onBlur={this.handleBlur}
                            placeholder="0.00"
                            size="large"
                            className="width-100"
                            precision={2}
                            addonBefore={<IconMoneda />}
                            addonAfter="MXN"
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                    <Form.Item
                        label={this.props.transaccion_id ? "Tipo" : <>Obligatorio</>}
                        name="tipo"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el tipo de transacción"
                        }]}
                    >
                        <Select
                            className="width-100"
                            size="large"
                            placeholder="Egreso/Ingreso"
                            disabled={true}
                        >
                            <Option value={1}>Ingreso</Option>
                            <Option value={2}>Egreso</Option>

                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={this.props.transaccion_id ? "Tipo Pago" : <>Obligatorio</>}
                        name="tipo_pago"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el tipo de pago"
                        }]}
                    >
                        <SelectTipoPago size="large" placeholder='Tipo Pago' disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label={this.props.transaccion_id ? "Referencia Númerica" : <>Obligatorio</>}
                        name="referencia_numerica"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese la referencia númerica con la que desea identificar esta transacción",
                        },
                        {
                            message: "La referencia solo puede ser números",
                            pattern: new RegExp(/[0-9]+/)

                        },
                        {
                            message: "La referencia solo tener máximo 7 caracteres",
                            max: 7
                        }
                        ]}
                    >
                        <Input
                            type="number"
                            placeholder="Referencia Númerica"
                            size="large"
                            className="width-100"

                        />
                    </Form.Item>



                    {(this.props.capturar) ? <>
                        <Divider>Fecha de Ejecución de Transacción</Divider>
                        <Form.Item
                            label={this.props.transaccion_id ? "Fecha Programada" : <>Obligatorio</>}
                            name="fecha_ejecucion"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese la fecha",
                            }
                            ]}>
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                size="large"
                                className="width-100"
                                placeholder="Seleccione la fecha"
                                
                                />
                        </Form.Item>
                        <Form.Item
                            label={this.props.transaccion_id ? "Hora Programada" : <>Obligatorio</>}
                            name="hora_ejecucion"
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese la hora",
                            }
                            ]}>
                            <TimePicker
                                format="HH:mm"
                                use12Hours={false}
                                //disabledTime={this.disabledTimes}
                                size="large"
                                className="width-100"
                                placeholder="Seleccione la hora" />
                        </Form.Item>
                    </> : null}
                    <Form.Item >
                        <Button htmlType="submit" type="primary" block size="large" className="mt-2">
                            Confirmar Transferencia
                        </Button>
                    </Form.Item>

                </Spin>
                <Modal2FA
                    visible={this.state.modalVisible2FA}
                    logged={this.props.usuario}

                    onFinish={({ token }) => {
                        if (this.props.transferir)
                            this.add({ ...this.state.values, token })

                        if (this.props.capturar)
                            this.capturar({ ...this.state.values, token })
                    }}
                />
            </Form >
        )
    }
}



export default function ModalTransferencia(props) {
    const { visible = false,
        onClose = () => { },
        cliente_id,
        capturar
    } = props

    const [key, setKey] = useState("1")

    const [beneficiario, setBeneficiario] = useState(null)



    const setValues = (value) => {
        setBeneficiario(value)
        setKey("1")
    }

    const usuario = useContext(Logged)

    const permissions = usePermissions(usuario?.rol_id?.permisos, {
        puedeVerSaldo: ["cuentas", "ver_saldo"],
    })

    return <Modal
        open={visible}
        onCancel={onClose}
        destroyOnClose={true}
        zIndex={1000}
        footer={false}
        closable={false}
    >
        <Row>
            <Col span={24} className="center">
                <img src={"/img/BXNKLogo.png"} width="164" />
            </Col>
        </Row>
        <Title level={3} className="text-center mb-2 mt-1">
            {props.transaccion_id ? "Editar Transacción" : "Crear Transacción"} { capturar ? "Programada" : null }
        </Title>
        <Tabs
            activeKey={key}
            defaultActiveKey={key}
        >
            <TabPane key="1">
                <FormTransferencia  {...permissions} usuario={usuario} {...props} setKey={setKey} beneficiario={beneficiario} />
            </TabPane>
            <TabPane key="2">
                <Space direction="horizontal" align="baseline" size="large" block className="p-1" style={{ marginLeft: 7 }}>
                    <Button icon={<ArrowLeftOutlined onClick={() => setKey("1")} />}></Button>
                    <Title level={5} className="text-center width-100">Nuevo Beneficiario</Title>
                </Space>
                <FormBeneficiario
                    {...props}
                    onClose={(value) => setValues(value)} beneficiario_id={null}
                    cliente_id={cliente_id}

                />
            </TabPane>
        </Tabs>
    </Modal >
}